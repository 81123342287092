<template>
    <div id="app_div">
          <van-overlay :show="load_show" >
             <div style="position: absolute;top: 45%;left: 44%;">
                <van-loading size="24px"  color="white" vertical>加载中...</van-loading>
             </div>
        </van-overlay>
        <div class="label_box">
        </div>
        <div class="btn_box">
        </div>
         <div class="register_div" v-show="show">
            <div class="tabs_div">
            </div>
            <van-tabs v-model="active">
                <van-tab title="手机号码登录">
                    <div class="tab_body">
                        <div class="tab_item">
                            <van-field
                                v-model="loginForm.mobile"
                                required
                                label="手机号"
                                placeholder="请输入手机号"
                                :error-message="loginForm.mobile_err"
                            />
                        </div>
                        <div class="tab_item">
                            <van-field
                                v-model="loginForm.code"
                                center
                                clearable
                                label="短信验证码"
                                placeholder="请输入短信验证码"
                                >
                                <template #button>
                                    <van-button size="small" :disabled ='code_second<60' @click="login_code" type="primary">{{code_msg}}</van-button>
                                </template>
                            </van-field>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="账号密码登录">
                    <div class="tab_body">
                        <van-cell-group>
                            <van-field
                                v-model="userNameLogin.loginName"
                                error
                                required
                                label="用户名"
                                placeholder="请输入用户名"
                            />
                            <van-field
                                v-model="userNameLogin.password"
                                required
                                type="password"
                                label="密码"
                                placeholder="请输入密码"
                            />
                        </van-cell-group>
                    </div>
                </van-tab>
            </van-tabs>
           
            <div class="tab_footer">
                <div style="height:30px;"></div>
                <div style="padding:0px 10px;">
                    <van-button type="primary" @click="userLogin" class="login_btn" block>验证登录</van-button>
                </div>
                <div style="height:20px;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {userNameLogin,priceMakerLogin,sendRegisterCode} from '../../api/login'
import {loginUrlSotre,tokenStore} from '../../store/token'
import {pricingUser} from '../../store/pricing'
import { Overlay,Loading,Button,Tab, Tabs,Field,CellGroup } from 'vant';
import {codeToOpenid} from '../../api/wxAuth'
export default ({
    name:'mAgentHome',
    components:{
        [Overlay.name]:Overlay,
        [Loading.name]:Loading,
        [Button.name]:Button,
        [Tab.name]:Tab,
        [Tabs.name]:Tabs,
        [Field.name]:Field,
        [CellGroup.name]:CellGroup,
    },
    data(){
        return {
            active:0,
            load_show:false,
            openId:'',
            show:true,
            code_interval:undefined,
            code_second:60,
            code_btn:false,
            register_btn_class:false,
            code_msg:"发送验证码",
            loginForm:{
                mobile:'',
                mobile_err:'请输入手机号码',
                code:'',
                code_err:'请输入验证码',
            },
            userNameLogin:{
                loginName:'',
                password:'',
            }
        }
    },
    methods:{
        userLogin(){
            console.log("login");
            if(this.active === 0){
                if(!this.loginForm.mobile || !this.loginForm.code){
                    this.$toast.fail('请输入手机号和验证码');
                    return
                }
                var body = {
                    "mobile": this.loginForm.mobile,
                    "code": this.loginForm.code,
                    "openId":this.openId
                }
                this.pricingLogin(body);
            }else{
                this.$toast.loading({duration: 0,forbidClick: true,message: '登录中',});               
                if(!this.userNameLogin.loginName || !this.userNameLogin.password){
                    this.$toast.clear();
                    this.$toast.fail('请输入账号和密码');
                    return
                }
                userNameLogin(this.userNameLogin)
                .then(res=>{
                    this.$toast.clear();
                    this.$toast({type:res.status?'success':'fail',message:res.message})
                    if(res.status){
                        var jsonObj = res.data;
                        tokenStore.set('Bearer '+res.data.token);
                        delete jsonObj.token
                        pricingUser.set(jsonObj)
                        this.$router.push("/pricingIndex")
                    }
                })
            }
        },
        // wxLogin(){
        //     this.$toast.loading({duration: 0,forbidClick: true,message: '微信登录中',});
        //     this.$api.wxLogin({openId:this.openId,state:2}).then(res=>{
        //         this.$toast.clear();
        //         console.log(res);
        //         if(res.id){
        //             Cookies.set('Authorization','Bearer '+res.token);
        //             Cookies.set('agentUserInfo',JSON.stringify(res));
        //             this.$router.replace("/pricingIndex");
        //         }else{
        //             console.log('this.show');
        //             this.show = true;
        //         }
        //     })
        // },
        mobileBlur(){
            console.log("mobile_blur");
            this.loginForm.mobile_err = !this.loginForm.mobile?"请输入手机号码":' ';
            if(!this.$util.checkPhone(this.loginForm.mobile)){
                this.loginForm.mobile_err = "手机号码格式错误";
            }
        },
        pricingLogin(body){
            this.$toast.loading({duration: 0,forbidClick: true,message: '账号登录中',});
            priceMakerLogin(body).then(res=>{
                this.$toast.clear();
                this.$toast({type:res.status?'success':'fail',message:res.message})
                if(res.status){
                    var jsonObj = res.data;
                    tokenStore.set('Bearer '+res.data.token);
                    delete jsonObj.token;
                    pricingUser.set(jsonObj)
                    this.$router.push("/pricingIndex")
                }
                console.log(res);
            });
        },
        login_code(){
            console.log("login_code");
            console.log(this.code_interval);
            this.mobileBlur();
            if(!this.$util.checkPhone(this.loginForm.mobile)){
                this.loginForm.mobile_err = "手机号码格式错误";
                return;
            }
            if(!this.code_interval){
                console.log('mobile');
                console.log(this.loginForm.mobile);
                sendRegisterCode({mobile:this.loginForm.mobile}).then((res)=>{
                   this.$toast(res.message);
                });
                this.code_btn = true;
                this.code_interval = setInterval(() => {
                    this.code_msg ="重新发送"+ this.code_second--;
                    if(this.code_second<=0){
                        this.code_msg = "发送验证码";
                        this.code_btn = false;
                        window.clearInterval(this.code_interval);
                        this.code_second = 60;
                        this.code_interval= undefined;
                    }
                }, 1000);
            } 
        },
        getCode () { // 非静默授权，第一次有弹框
            this.code = ''
            var local = window.location.href // 获取页面url
            var appid = this.$enum.wx_appid;//this.$enum.wx_appid;
            var obj = this.getUrlCode();
            this.code = obj.code // 截取code
            this.state = obj.state 
           
            if (!this.code) { // 如果没有code，则去请求
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
            } else {
                this.$toast.loading({duration: 0,forbidClick: true,message: '微信授权中',});
                codeToOpenid({code:this.code})
                .then(res=>{
                    this.$toast.clear();
                    // console.log(res)
                    res =res.data;
                    if(res.code == 500){
                        this.$toast.fail(res.msg);
                    }else{
                        if(!res.data){
                            this.$toast.fail('请求错误，请刷新重试！');
                            return;
                        }
                        this.openId = res.data;
                        window.localStorage.setItem('openId', this.openId);
                        // this.login(this.openId);
                    }
                })
            }
        },
        getUrlCode() { // 截取url中的code方法
                var url = location.search
                this.winUrl = url
                var theRequest = new Object()
                if (url.indexOf("?") != -1) {
                    var str = url.substr(1)
                    var strs = str.split("&")
                    for(var i = 0; i < strs.length; i ++) {
                        theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
                    }
                }
                return theRequest
            },
        },    
    mounted(){       
        var openid = window.localStorage.getItem('openId');
        this.openId = openid;
        // if(this.$global.isWeiXin){
        //     if(!this.openId){ // 如果缓存localStorage中没有微信openId，则需用code去后台获取
        //         this.getCode();
        //     }
        // }
        setTimeout(() => {
            console.log("register_btn_class");
            this.register_btn_class = true;
        }, 1000);
    },
    created(){
        loginUrlSotre.set('/pricingLogin');
        var token = tokenStore.get();
        if(token){
            this.$router.push('/pricingIndex')
        }
    }
})
</script>
<style scoped>
@import "./pricingLogin.css";
</style>